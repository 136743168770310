<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <v-row>
        <v-col cols="3">
        <v-card flat outlined class="widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}">
            <v-subheader>Report Types</v-subheader>
            <v-divider></v-divider>
        <v-card flat outlined class="widgetype-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '0px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}">
            <v-row >
                <v-col>
                    <v-list :color="$store.getters.getColorPalette().backgroundColorCode" dense>
                        <template  v-for="(item) in reportTypeOptions">
                            <v-list-item  v-if="item.label && item.condition" :key="item.label" @click="handleItemClick(item)">
                                <v-list-item-action>
                                    <v-icon :style="{color:$store.getters.getColorPalette().accentCode}">{{item.icon}}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                <!-- <v-btn text outlined small> -->
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                <!-- </v-btn> -->
                                </v-list-item-content>
                            </v-list-item>          
                        </template>
                    </v-list>
                </v-col>
            </v-row>
            </v-card>
        </v-card>
        </v-col>
        <v-col cols="9">
        <v-card  flat outlined class=" widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                <component :is="getReport()"  v-on:close="dialog=false"/>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>
<script>
import CreateParameterReport from '@/components/crud_components/CreateParameterReport'
import CreateEnergyUsageReport from '@/components/crud_components/CreateEnergyUsageReport'
import CreateEnergyReport from '@/components/crud_components/CreateEnergyReport'
import CreateEnergyDistributionReport from '@/components/crud_components/CreateEnergyDistributionReport'
import CreateEnergyBillDistReport from '@/components/crud_components/CreateEnergyBillDistReport'
// import CreateParameterSECReport from '@/components/crud_components/CreateParameterSECReport'
// import CreateGranularSECReport from '@/components/crud_components/CreateGranularSECReport'
import CreateSECReport from '@/components/crud_components/CreateSECReport'
import CreateConsolidatedReport from '@/components/crud_components/CreateConsolidatedReport'
import CreateDynamicExcelReport from '@/components/crud_components/CreateDynamicExcelReport'
import CreateUsageReport from '@/components/crud_components/CreateUsageReport'
import CreateExcelTemplateBasedReport from '@/components/crud_components/reports/CreateExcelTemplateBasedReport'
import CreateEnergyAnalysisReport from '@/components/crud_components/reports/CreateEnergyAnalysisReport'
export default {
    name:'ReportPanel',
    components:{
        CreateParameterReport,
        CreateEnergyUsageReport,
        CreateEnergyReport,
        CreateEnergyDistributionReport,
        CreateEnergyBillDistReport,
        // CreateParameterSECReport,
        // CreateGranularSECReport,
        CreateConsolidatedReport,
        CreateDynamicExcelReport,
        CreateUsageReport,
        CreateSECReport,
        CreateExcelTemplateBasedReport,
        CreateEnergyAnalysisReport
    },
    data(){
        return {
            report_type:null,
            compMap:{
                'createParameterReport':CreateParameterReport,
                'createEnergyUsageReport':CreateEnergyUsageReport,
                'createEnergyReport':CreateEnergyReport,
                'createEnergyDistributionReport':CreateEnergyDistributionReport,
                'createEnergyBillDistReport':CreateEnergyBillDistReport,
                // 'createParameterSECReport':CreateParameterSECReport,
                // 'createGranularSECReport':CreateGranularSECReport,
                'createConsolidatedReport':CreateConsolidatedReport,
                'createDynamicExcelReport':CreateDynamicExcelReport,
                'createUsageReport':CreateUsageReport,
                'createSECReport':CreateSECReport,
                'createExcelTemplateBasedReport':CreateExcelTemplateBasedReport,
                'createEnergyAnalysisReport':CreateEnergyAnalysisReport,
            },
            reportTypeOptions:[
                {label:'Usage Report',value:'createUsageReport',icon:'mdi-chart-bar',condition:this.$store.state.user},
                {label:'Consolidated Report',value:'createConsolidatedReport',icon:'mdi-consolidate',condition:this.$store.state.user},
                {label:'Dynamic Excel Report',value:'createDynamicExcelReport',icon:'mdi-file-document-multiple-outline',condition:this.$store.state.user},
                {label:'Energy Usage Report',value:'createEnergyUsageReport',icon:'mdi-transmission-tower',condition:this.$store.state.user},
                {label:'Energy Distribution Report',value:'createEnergyDistributionReport',icon:'mdi-graph-outline',condition:this.$store.state.user},
                {label:'Bill Distribution Report',value:'createEnergyBillDistReport',icon:'mdi-invoice-list-outline',condition:this.$store.state.user},
                {label:'Timeframe SEC Report',value:'createSECReport',icon:'mdi-clock-outline',condition:this.$store.state.user},
                {label:'Template Based Report',value:'createExcelTemplateBasedReport',icon:'mdi-file-hidden',condition:this.$store.state.user},
                {label:'Parameter Report',value:'createParameterReport',icon:'mdi-clipboard-text-outline',condition:this.$store.state.user  && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                {label:'Energy Report',value:'createEnergyReport',icon:'mdi-lightning-bolt-outline',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                {label:'Energy Analysis Report',value:'createEnergyAnalysisReport',icon:'mdi-home-analytics',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['betaaccess'])},
                // {label:'Parameter SEC Report',value:'createParameterSECReport',icon:'mdi-clipboard-text-outline',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                // {label:'Granular SEC Report',value:'createGranularSECReport',icon:'mdi-clipboard-text-outline',condition:this.$store.state.user  && this.$store.getters.getAccessByLevels(['alphaaccess'])},
          ]
        }
    },
    methods:{
        getReport(){
            return this.compMap[this.report_type]
        },
        handleItemClick(item){
            this.report_type = item.value
        },
    }
}
</script>
<style scoped>
.widget-card-container {
height: 560px;
  max-height: 560px;
  overflow-y: auto;
}
.widgetype-card-container {
height: 550px;
  max-height: 100%;
  overflow-y: auto;
}
</style>