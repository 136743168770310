<template>
  <v-container fluid>
    <InfoAlert
      :info="info"
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
    />
    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col>
          <h2>
            {{ edit?$store.getters.getTextMap().update_energy_analysis_report:$store.getters.getTextMap().create_energy_analysis_report }}
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            label="Report Name"
            counter="50"
            v-model="form.name"
            :rules="nameRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :rules="DescriptionRules"
            dense
            outlined
            label="Report Description"
            counter="50"
            v-model="form.msg"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            dense
            outlined
            label="Time Frame"
            v-model="form.freq"
            :items="timeframes"
            item-text="label"
            item-value="value"
            @change="resetDateTimePicker"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            dense
            outlined
            label="Notification Group"
            v-model="form.notification_group"
            :items="notificationGroupList"
            item-text="label"
            item-value="value"
            :rules="[rules.required]"
          ></v-select>
        </v-col>

        <!-- Conditionally show Date or Time picker based on selected frequency -->
        <v-col cols="12" md="6" v-if="form.freq === 'week'">
          <v-select
            dense
            outlined
            label="Select Day of the Week"
            v-model="selectedDay"
            :items="weekdays"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="form.freq === 'month'">
          <v-text-field
            dense
            outlined
            label="Day of the Month (1-31)"
            type="number"
            v-model.number="selectedDate"
            min="1"
            max="31"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            label="Select Time"
            readonly
            v-model="selectedTime"
            @click="timePickerDialog = true"
          ></v-text-field>
        </v-col>

        <v-dialog v-model="timePickerDialog" persistent max-width="350px">
          <v-card>
            <v-card-title>Select Time</v-card-title>
            <v-card-text>
              <v-time-picker
                v-model="selectedTime"
                format="24hr"
              ></v-time-picker>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="timePickerDialog = false"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row>
        <v-col class="d-flex justify-center items-center">
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="onSubmit"
            class="mx-5"
            >Submit</v-btn
          >
          <v-btn
            :color="$store.getters.getColorPalette().cancelbtnColor"
            @click="onReset"
            >Clear</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert";
import FormRules from "@/utillities/FormRules";
import axios from "axios";

export default {
  name: "CreateEnergyAnalysisReport",
  components: { InfoAlert },
  props: ["report"],
  data() {
    return {
      rules: FormRules.rules,
      info: "",
      showDismissibleAlert: false,
      timePickerDialog: false,
      isValid: false,
      loading: false,
      form: {
        name: null,
        msg: null,
        freq: "week",
        notification_group: null,
      },
      selectedTime: "08:16", // Default time
      selectedDay: null,
      selectedDate: 1,
      api: "createEnergyAnalysisReport",
      timeframes: [
        { label: "Daily", value: "day" },
        { label: "Weekly", value: "week" },
        { label: "Monthly", value: "month" },
      ],
      edit: false,
      weekdays: [
        { text: "Sunday", value: 0 },
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => /\S+/.test(v) || "Name is required",
        (v) =>
          (v && v.length <= 50 && v.length > 0) ||
          "Name must be less than 50 characters",
      ],
      DescriptionRules: [
        (v) => !!v || "Description is required",
        (v) => /\S+/.test(v) || "Description is required",
        (v) =>
          (v && v.length <= 50 && v.length > 0) ||
          "Description must be less than 50 characters",
      ],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    notificationGroupList() {
      return this.$store.state.notificationGroups.map((i) => ({
        label: i.name,
        value: i.group_id,
      }));
    },
  },
  methods: {
    init() {
      console.log(this.report);
      if (this.report) {
        this.edit = true;
        this.form = this.report;
        console.log(this.report.schedule);
        
        const { selectedTime, freq, selectedDay, selectedDate } = this.parseCronExpression(this.report.schedule);
    
    this.selectedTime = selectedTime;
    this.form.freq = freq;
    this.selectedDay = selectedDay;
    this.selectedDate = selectedDate;

        
        this.api = "updateEnergyAnalysisReport";
      } else {
        this.api = "createEnergyAnalysisReport";
        this.edit = false;
        this.form = {
          name: null,
          msg: null,
          freq: "week",
          notification_group: null,
          machines: [],
        };
      }
    },
    resetDateTimePicker() {
      this.selectedTime = "08:16";
      this.selectedDay = null;
      this.selectedDate = 1;
    },
    generateCronExpression() {
      let [hour, minute] = this.selectedTime.split(":");
      switch (this.form.freq) {
        case "day":
          return `${minute} ${hour} * * *`;
        case "week":
          return `${minute} ${hour} * * ${this.selectedDay}`;
        case "month":
          return `${minute} ${hour} ${this.selectedDate} * *`;
        default:
          return "";
      }
    },
    parseCronExpression(cron) {
      const [minute, hour, dayOfMonth, dayOfWeek] = cron.split(" ");

      let selectedTime = `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
      let freq = "day";
      let selectedDay = null;
      let selectedDate = null;

      if (dayOfWeek !== "*" && dayOfMonth === "*") {
        freq = "week";
        selectedDay = dayOfWeek; 
      } else if (dayOfMonth !== "*" && dayOfWeek === "*") {
        freq = "month";
        selectedDate = dayOfMonth;
      }

      return {
        selectedTime,
        freq,
        selectedDay,
        selectedDate,
      };
    },

    onSubmit() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.form.schedule = this.generateCronExpression();
        this.loading = true;
        let payload = { ...this.form };
        let apiHeaders = { Authorization: "Bearer " + this.$store.state.jwt };
        axios
          .post(this.$store.state.api + this.api, payload, {
            headers: apiHeaders,
          })
          .then((response) => {
            if (response.data.status === "success") {
              if (this.edit) {
                this.loading = false;
                this.info = "Report Updated Successfully";
                this.$store.dispatch("refreshEnergyAnalysisReports");
                this.showDismissibleAlert = true;
              } else {
                this.loading = false;
                this.info = "Report Created Successfully";
                this.$store.dispatch("refreshEnergyAnalysisReports");
                this.showDismissibleAlert = true;
                this.onReset();
              }
            }
          })
          .catch((err) => {
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
    onReset() {
      this.$refs.form.reset();
      this.resetDateTimePicker();
    },
  },
};
</script>