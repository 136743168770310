<template>
    <h1>EnergyAnalysisReportDetails</h1>
</template>

<script>
export default {
    name:"EnergyAnalysisReportDetails",
    data(){
        return{
            file:""
        }
    }
}
</script>

<style>

</style>